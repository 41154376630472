/* 
==========================================================================
	 General
========================================================================== 
*/

#main {
    transition-timing-function: ease-in-out;
    /* overflow-x: hidden;
    overflow-y: scroll; */
    background: #F2F2F2;
    /* padding: 0 0 30px !important; */
    /* margin-left: 300px; */
    /* width: calc(100% -300px); */
    width: 100%;
    height: 100%;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1040;
    width: calc(100vw - 300px);
    margin-left: 300px;
    height: 100vh;
    background-color: #000;
}

.modal-dialog {
    min-width: 500px;
    min-height: 100%;
    margin: 1.75rem auto;
    /* margin-left: auto; */
}

.modal-body {
    justify-content: center;
    min-height: 100%;
}

.add-shadow {
    -webkit-box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.none {
    display: none;
}

@media screen and (max-width: 1499px) {
    .modal-dialog {
        min-width: 500px;
        min-height: 100%;
        margin: 1.75rem 0;
        margin-left: 450px;
    }
    #location-modal {
        margin-left: 600px;
    }
}

@media only screen and (max-width: 1700px) {
    div.container {
        width: calc(100% - 300px);
        margin-left: 300px;
        padding-right: 30px;
    }
}
@font-face {
    font-family: 'Circular';
    src: url('./assets/fonts/OTF/CircularStd-Book.otf') format('opentype');
    font-weight: lighter;
}


/* @font-face {
    font-family: 'Metropolis';
    src: url('./assets/Webfonts/EOT/Metropolis-Regular.eot') format('truetype');
    src: url('./assets/Webfonts/WOFF/Metropolis-Regular.woff') format('truetype');
    src: url('./assets/Webfonts/WOFF2/Metropolis-Regular.woff2') format('truetype');
    src: url('./assets/Webfonts/OTF/CircularStd-Medium.otf') format('opentype');
    font-weight: normal;
} */

@font-face {
    font-family: 'Circular';
    src: url('./assets/fonts/OTF/CircularStd-Medium.otf') format('opentype');
    font-weight: medium;
}

@font-face {
    font-family: 'Circular';
    src: url('./assets/fonts/OTF/CircularStd-Bold.otf') format('opentype');
    font-weight: bold;
}

*,
 ::after,
 ::before {
    box-sizing: border-box;
}

body,
html {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    /* -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */
}

h1,
h2,
h3,
h4,
h5 {
    font-family: 'Circular', sans-serif;
}

p,
a,
div,
section,
span,
button,
input,
textarea,
optgroup,
select {
    font-family: 'Circular', Helvetica;
    /* font-family: 'Poppins', sans-serif; */
}

a,
a:hover {
    text-decoration: none !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

button {
    cursor: pointer;
}

#page-numbers {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    color: #0B69A3 !important;
    padding-bottom: 30px;
}

#page-numbers div {
    margin-left: 40px;
    margin-right: 30px;
    display: flex;
    justify-content: center;
}

#page-numbers span {
    color: #0B69A3 !important;
    margin: 10px;
    /* text-transform: capitalize; */
    font-size: 14px;
}

#page-numbers button {
    color: #0B69A3 !important;
    background-color: transparent;
    border: transparent;
}

#page-numbers button img.double_arrow {
    width: 10px;
    height: 10px;
}

.nav.nav-tabs {
    padding: 0;
    border-bottom: 1px solid rgba(151, 151, 151, .1);
    background: transparent;
    width: 100%;
}

.nav-item.nav-link {
    display: block;
    padding: 12px 0;
    margin-right: 32px;
    background-color: transparent;
    color: #565F62;
    font-size: 16px;
    line-height: 20px;
}

.nav-item.nav-link.active {
    border: none;
    border-bottom: 2px solid #F8634B;
    background: transparent;
    color: #071013;
}

.nav-item.nav-link:hover {
    border: none;
    border-bottom: 1px solid #F8634B;
}

.tab-content {
    padding: 24px 0 20px;
    background: #f2f2f2;
    width: 100%;
}

.tox .tox-statusbar a {
    display: none;
}

#doughnut,
#pie {
    /* width: 150px !important; */
    height: 173px !important;
    margin-right: 40px !important;
}